.scenario-flex-layout {
  margin-top: 0.5rem;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.sticky-tabs {
  position: sticky;
  top: 0;
  z-index: 200; 
  background: white; 
  height: 3rem;
}

.Polaris-Popover__PopoverOverlay--open {
  z-index: 1001;
}
