@keyframes slideIn {
  from {
    width: 5rem;
  }
  to {
    width: 16rem;
  }
}

@keyframes slideOut {
  from {
    width: 16rem;
  }
  to {
    width: 5rem;
  }
}

.sidebar-open {
  animation: slideIn 0.3s forwards;
}

.sidebar-closed {
  animation: slideOut 0.3s forwards;
}

@keyframes contentSlideIn {
  from {
    padding-left: 5rem;
  }
  to {
    padding-left: 16rem;
  }
}

@keyframes contentSlideOut {
  from {
    padding-left: 16rem;
  }
  to {
    padding-left: 5rem;
  }
}

.content-open {
  animation: contentSlideIn 0.3s forwards;
}

.content-closed {
  animation: contentSlideOut 0.3s forwards;
}
