@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.hide {
  display: none;
}

.event-stats {
    border: 0.5px solid #000;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.stat-font {
    font-size: 1rem;
}

.stat-label-font {
    font-size: 1rem;
    align-self: center;
}

.scenario-container {
  scroll-snap-type: y mandatory;
  overflow-y: auto;
  height: 100vh;
}

.section {
  scroll-snap-align: start;
  height: 50vh; /* Half of the viewport height */
  /* Additional styling for layout and alignment */
}

/* Container to control the width and centering */
.content-container {
  margin: 0 auto; /* Centers content horizontally */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers content vertically */
  height: 100%; /* Full height of the section */
}

/* Media query for XL screens */
@media (min-width: 1200px) { /* Adjust the min-width as needed for your definition of XL screens */
  .content-container {
    max-width: 1200px; /* Set a max-width for your content on larger screens */
  }


  .section {
    scroll-snap-align: none;
    height: auto;
  }
}

.chart-container {
  position: relative;
}

.no-data-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10; /* Ensure it appears above the chart */
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 5px;
}

.page-padding {
  padding: 20px;
}


.cus_drop span.Polaris-Button__Icon {
  display: none !important;
}
.cus_drop .Polaris-Button {
  min-height: 40px !important;
  margin-top: 0px !important;
}

.sidebar_menu .active {
  background-color: #303030;
}
.cardheight .Polaris-Box{
  height: 100%;
}