.card-dimensions {
    height: 350px !important;
    max-height: 350px !important;
    min-height: 350px !important;
    width: 340px !important;
    max-width: 340px !important;
    min-width: 340px !important;
    overflow: hidden;
}

.card-holder {
    height: 11rem; /* 176px */
    padding: 0.5rem; /* 8px */
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-image {
    border-radius: 9999px;
    width: 10rem; /* 160px */
    height: 10rem; /* 160px */
}

/* .card-description {
    height: 50px; 
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
} */
.card-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

